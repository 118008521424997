import { Component, Vue, Ref, Prop } from "vue-property-decorator";
import {
  getSymbolCurrency,
  cloneStructured,
  getPaymentMethodLogo,
  isPositiveNumber,
  isEmpty,
  numberToFixed,
} from "@helpers";
import { BillingActions } from "@store/modules/billing/types";
import { billingModule } from "@store/namespaces";
import {
  PaymentMethodId,
  PaymentMethod as IPaymentMethod,
} from "@/types/billing";
import { Currency } from "@/types/currency";
import { VForm } from "@/types/vuetify";
import { depositWithoutCommission } from "@config/welcome";

@Component
export default class RefillForm extends Vue {
  @billingModule.Action("createBillingInvoice")
  private readonly createBillingInvoiceAction!: BillingActions["createBillingInvoice"];

  @Ref("form") private readonly formRef!: VForm;
  @Prop({ type: Boolean, default: false })
  private readonly isFirstRefillForUser!: boolean;
  @Prop({ type: Object, required: true })
  private readonly data!: IPaymentMethod;

  private creatingInvoice = false;
  private createInvoiceForm: {
    paymentMethod: PaymentMethodId;
    amount: string;
    currency: {
      list: Currency[];
      current: Currency;
    };
  } | null = null;

  private get minAmount() {
    return this.data.minAmount || 0;
  }

  private get maxAmount() {
    return this.data.maxAmount || 0;
  }

  private get comission() {
    const currentCurrency = this.createInvoiceForm?.currency.current;

    if (
      currentCurrency &&
      this.isFirstRefillForUser &&
      depositWithoutCommission.maxAmount >= this.invoiceAmount &&
      depositWithoutCommission.currency === currentCurrency
    ) {
      return 0;
    }

    return this.data.comission || 0;
  }

  private get isShowCreateInvoiceDialog() {
    return !!this.createInvoiceForm;
  }

  private set isShowCreateInvoiceDialog(val) {
    this.createInvoiceForm = val ? this.createInvoiceForm : null;
  }

  private get fieldRules() {
    return {
      required: (v: string) =>
        !isEmpty(v) || this.$vuetify.lang.t("$vuetify.errors.required"),
      onlyDigits: (v: string) =>
        !isNaN(+v) || this.$vuetify.lang.t("$vuetify.errors.only_digits"),
      amount: (v: string) => {
        if (!isPositiveNumber(v)) {
          return this.$vuetify.lang.t("$vuetify.errors.positive_number");
        }

        if (+v < this.minAmount) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.min_amount",
            `${this.minAmount}${getSymbolCurrency(
              this.createInvoiceForm?.currency.current
            )}`
          );
        }

        if (+v > this.maxAmount) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.max_amount",
            this.maxAmount
          );
        }

        return true;
      },
    };
  }

  private get validCreateInvoiceForm() {
    return this.fieldRules.amount(this.invoiceAmount.toString()) === true;
  }

  private get comissionAmount() {
    const amount = this.invoiceAmount;
    const comission = this.comission;

    if (isPositiveNumber(amount) && comission) {
      return numberToFixed(amount * comission, 2);
    }

    return 0;
  }

  private get invoiceAmount() {
    return +(this.createInvoiceForm?.amount || "");
  }

  private get currencyIcon() {
    return require(`@/assets/img/currency/flags/${this.createInvoiceForm?.currency.current}.svg`);
  }

  private get currencySymbol() {
    return getSymbolCurrency(this.createInvoiceForm?.currency.current);
  }

  private get paymentMethodLogo() {
    return getPaymentMethodLogo(this.data.id);
  }

  private async createBillingInvoice() {
    if (
      this.creatingInvoice ||
      !this.createInvoiceForm ||
      !this.validCreateInvoiceForm ||
      !this.formRef.validate()
    )
      return;

    const { paymentMethod, currency } = this.createInvoiceForm;

    this.creatingInvoice = true;

    try {
      const { success, html } = await this.createBillingInvoiceAction({
        amount: this.invoiceAmount,
        paymentMethod,
        enrollCurrency: currency.current,
      });

      if (success && html) {
        document.open();
        document.write(html);
        document.close();
      }

      this.creatingInvoice = false;
    } catch (error) {
      this.creatingInvoice = false;
    }
  }

  private created() {
    const { id, availableCurrency } = cloneStructured(this.data);

    this.createInvoiceForm = {
      paymentMethod: id,
      amount: "",
      currency: {
        list: availableCurrency,
        current: availableCurrency[0],
      },
    };
  }
}
